export default {
  google: {
    maps: {
      apiKey: 'AIzaSyCGXMgM2THiVdCaNk3_TdHrge11LaJb8YA'
    },
    analytics: {
      apiKey: 'G-1PDPMPRCVS'
    }
  },
  mapbox: {
    apiKey: 'pk.eyJ1IjoiYWR2aXNyIiwiYSI6ImNsbzRoYW1lcDAxZHgyb2xtazQxc3JyNTMifQ.pj7-xtQn_4o9Y9PJv2FB7g'
  },
  rollbar: {
    accessToken: 'f6f0cb5d42c5441b898b38357d71d93f'
  }
}